import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import CreateEventFields from '@defs/CreateEventFields'
import { CreateEventSectionProps } from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form'

export interface HookCreateEventInputProps<T extends string = keyof CreateEventFields> {
    name: T
    label: string
    validation: RegisterOptions<FieldValues, T>
    inputType: React.HTMLInputTypeAttribute
    inputMode?: React.HTMLAttributes<{}>['inputMode']
    parentFormVariant: CreateEventSectionProps['variant']
    triggerValidationInAnotherField?: string[]
}

function HookCreateEventInput<T extends string = keyof CreateEventFields>({
    name,
    label,
    validation,
    parentFormVariant,
    inputType,
    inputMode,
    triggerValidationInAnotherField,
}: HookCreateEventInputProps<T>) {
    const { control, formState, trigger, getValues } = useFormContext()

    const inputVariant = parentFormVariant === 'white' ? 'createEventGreen' : 'createEventWhite'

    const isInvalid = !!_.get(formState.errors, name)

    return (
        <FormControl isInvalid={isInvalid}>
            <FormLabel>{label}</FormLabel>
            <Controller
                control={control}
                name={name as any}
                rules={validation}
                render={({ field }) => (
                    <Input
                        {...field}
                        type={inputType}
                        id={name}
                        variant={inputVariant}
                        inputMode={inputMode}
                        onBlur={() => {
                            field.onBlur()

                            if (triggerValidationInAnotherField) {
                                const values = getValues()

                                triggerValidationInAnotherField.forEach((fieldName) => {
                                    if (values[fieldName] && values[fieldName] !== '') {
                                        trigger(fieldName)
                                    }
                                })
                            }
                        }}
                    />
                )}
            />
            {isInvalid ? (
                <FormErrorMessage>
                    {_.get(formState.errors, name)?.message?.toString() ?? ''}
                </FormErrorMessage>
            ) : null}
        </FormControl>
    )
}

export default HookCreateEventInput
