import { GetEventImageMapResponse } from '@commonwealthventures/image-service-api-models'
import Result from '@defs/Result'
import axios, { AxiosError } from 'axios'
import _ from 'lodash'
import getS3SignedUrl, { ImageField } from './getS3SignedUrl.service'
import getImageMap from './getImageMap.service'

function sleep(duration: number) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(null)
        }, duration)
    })
}

async function waitForImageMapUpdate({
    eventId,
    field,
    originalImageMap,
    maxRetries,
    retryCount = 0,
}: {
    eventId: string
    field: ImageField
    originalImageMap: GetEventImageMapResponse
    maxRetries: number
    retryCount?: number
}): Promise<Result<void>> {
    let updatedMap: GetEventImageMapResponse

    try {
        updatedMap = await getImageMap(eventId)
    } catch (err) {
        return {
            success: false,
            error: `Error validating that image at path ${field} was uploaded. Failed to get image map from server`,
        }
    }

    const formattedKey = field.split('/').join('.')

    const originalKey = _.get(originalImageMap, formattedKey)
    const mapKey = _.get(updatedMap, formattedKey)

    if (mapKey && mapKey !== originalKey) {
        return {
            success: true,
        }
    }

    if (retryCount < maxRetries) {
        await sleep(500)
        return waitForImageMapUpdate({
            eventId,
            field,
            originalImageMap,
            maxRetries,
            retryCount: retryCount + 1,
        })
    }

    return {
        success: false,
        error: `Timed out waiting for image at path ${field} to updated`,
    }
}

export default async function uploadImage({
    eventId,
    field,
    file,
    authToken,
    originalImageMap,
    onUploadProgress,
}: {
    eventId: string
    field: ImageField
    file: File
    authToken: string
    originalImageMap: GetEventImageMapResponse
    onUploadProgress: (loaded: number) => void
}): Promise<Result<void>> {
    const { uploadUrl, tagHeaderToSet } = await getS3SignedUrl(eventId, field, authToken)

    try {
        await axios.put(uploadUrl, file, {
            headers: {
                'Content-Type': file.type,
                'x-amz-tagging': tagHeaderToSet,
            },
            onUploadProgress: (uploadEvent) => {
                onUploadProgress(uploadEvent.loaded * 100)
            },
        })
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error uploading image. ERROR:', (err as AxiosError).toJSON())

        return {
            success: false,
            error: 'failed to upload image',
        }
    }

    const waitForUpdateResult = await waitForImageMapUpdate({
        eventId,
        field,
        originalImageMap,
        maxRetries: 20,
    })

    if (!waitForUpdateResult.success) {
        return {
            success: false,
            error: waitForUpdateResult.error,
        }
    }

    return {
        success: true,
    }
}
