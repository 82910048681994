import {
    Button,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Icon,
    IconButton,
    Radio,
    RadioGroup,
    VStack,
} from '@chakra-ui/react'
import { CurrencyV1 } from '@commonwealthventures/poc-readstore-v1-models'
import CreateEventFields from '@defs/CreateEventFields'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { Fragment } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { MdAdd, MdDelete } from 'react-icons/md'

export const newTicketOption: Pick<CreateEventFields['ticketOptions'][number], 'name'> = {
    name: '',
}
export interface TicketDetailsFormProps extends CreateEventSectionProps {}

const formCurrencyName: keyof CreateEventFields = 'currency'
const formTicketDetailsName: keyof CreateEventFields = 'ticketOptions'
type TicketOptionsGeneric =
    `${string}.${number}.${keyof CreateEventFields['ticketOptions'][number]}`

function TicketDetailsForm({ variant }: TicketDetailsFormProps) {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: formTicketDetailsName,
    })

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Ticket details:
            </Heading>
            <FormControl>
                <FormLabel>Currency</FormLabel>
                <Controller
                    name={formCurrencyName}
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field}>
                            <VStack alignItems="start">
                                {Object.values(CurrencyV1).map((v) => (
                                    <Radio value={v}>{v}</Radio>
                                ))}
                            </VStack>
                        </RadioGroup>
                    )}
                />
            </FormControl>

            {fields.map((item, idx) => (
                <Fragment key={item.id}>
                    <HStack spacing={4}>
                        <Heading as="h4" fontSize="xl" mb={2}>
                            Ticket option {idx + 1}:
                        </Heading>
                        {fields.length > 1 ? (
                            <IconButton
                                w="8"
                                minW="8"
                                h="8"
                                minH="8"
                                aria-label={`Remove ticket option ${idx + 1}`}
                                icon={<MdDelete />}
                                onClick={() => remove(idx)}
                            />
                        ) : null}
                    </HStack>

                    <HookCreateEventInput<TicketOptionsGeneric>
                        name={`${formTicketDetailsName}.${idx}.name`}
                        label="Ticket option name:"
                        parentFormVariant={variant}
                        inputType="text"
                        validation={{
                            required: 'Ticket option name is required',
                        }}
                    />

                    <HookCreateEventInput<TicketOptionsGeneric>
                        name={`${formTicketDetailsName}.${idx}.priceInPence`}
                        label="Price in pence:"
                        parentFormVariant={variant}
                        validation={{
                            valueAsNumber: true,
                            validate: (value) => {
                                if (Number.isNaN(value)) {
                                    return 'Funding threshold must be a number'
                                }
                                if (value < 1) {
                                    return 'Funding threshold must be greater than zero'
                                }
                                if (value % 1 !== 0) {
                                    return 'Funding threshold must be a whole number'
                                }
                                return true
                            },
                        }}
                        inputType="number"
                        inputMode="numeric"
                    />
                </Fragment>
            ))}
            <Button
                variant="textButton"
                onClick={() => {
                    append(_.cloneDeep(newTicketOption))
                }}
                fontSize="md"
                my="-4"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another ticket option
            </Button>
        </CreateEventSection>
    )
}

export default TicketDetailsForm
