export default function inputDateToUtcDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-')

    const dateAsDate = new Date()
    dateAsDate.setFullYear(Number.parseInt(year, 10))
    dateAsDate.setMonth(Number.parseInt(month, 10) - 1)
    dateAsDate.setDate(Number.parseInt(day, 10))
    dateAsDate.setHours(0)
    dateAsDate.setMinutes(0)
    dateAsDate.setSeconds(0)
    dateAsDate.setMilliseconds(0)

    return dateAsDate
}
