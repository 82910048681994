import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Input,
} from '@chakra-ui/react'
import CreateEventFields from '@defs/CreateEventFields'
import { CreateEventSectionProps } from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import {
    FieldErrors,
    FieldValues,
    RegisterOptions,
    useFieldArray,
    useFormContext,
} from 'react-hook-form'
import { MdAdd, MdDelete } from 'react-icons/md'

export interface HookCreateEventMultiInputProps<T extends string = keyof CreateEventFields> {
    name: T
    label: string
    validation: RegisterOptions<FieldValues, T>
    inputType: React.HTMLInputTypeAttribute
    inputMode?: React.HTMLAttributes<{}>['inputMode']
    parentFormVariant: CreateEventSectionProps['variant']
    addButtonText: string
    removeButtonAriaText: string
}

function HookCreateEventMultiInput<T extends string = keyof CreateEventFields>({
    name,
    label,
    validation,
    parentFormVariant,
    inputType,
    inputMode,
    addButtonText,
    removeButtonAriaText,
}: HookCreateEventMultiInputProps<T>) {
    const { control, register, formState } = useFormContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    })

    const inputVariant = parentFormVariant === 'white' ? 'createEventGreen' : 'createEventWhite'

    return (
        <>
            {fields.map((field, idx) => {
                const isInvalid =
                    !!_.get(formState.errors, name) &&
                    !!(_.get(formState.errors, name) as unknown as FieldErrors[])[idx]

                return (
                    <FormControl isInvalid={isInvalid} key={field.id}>
                        {idx === 0 ? <FormLabel>{label}</FormLabel> : null}
                        <HStack w="full" alignItems="flex-start" spacing={4}>
                            <Input
                                w="full"
                                key={field.id}
                                type={inputType}
                                inputMode={inputMode}
                                id={field.id}
                                variant={inputVariant}
                                {...register<string>(`${name}.${idx}.value`, validation)}
                            />
                            {fields.length > 1 ? (
                                <IconButton
                                    aria-label={`${removeButtonAriaText} ${idx}`}
                                    icon={<MdDelete />}
                                    w={10}
                                    minW={10}
                                    h={10}
                                    minH={10}
                                    p={0}
                                    onClick={() => remove(idx)}
                                />
                            ) : null}
                        </HStack>
                        <FormErrorMessage>
                            {isInvalid
                                ? (_.get(formState.errors, name) as unknown as FieldErrors[])[
                                      idx
                                  ].value?.message?.toString() ?? ''
                                : ''}
                        </FormErrorMessage>
                    </FormControl>
                )
            })}
            <Button
                variant="textButton"
                onClick={() => {
                    append({ value: '' } as any)
                }}
                fontSize="md"
                my="-4"
            >
                <Icon as={MdAdd} boxSize={4} /> {addButtonText}
            </Button>
        </>
    )
}

export default HookCreateEventMultiInput
