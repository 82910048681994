import { Button, Heading, HStack, Icon, IconButton } from '@chakra-ui/react'
import CreateEventFields from '@defs/CreateEventFields'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { MdAdd, MdDelete } from 'react-icons/md'

export const newProvider: CreateEventFields['providers'][number] = {
    name: '',
    bio: '',
}
export interface ProvidersFormProps extends CreateEventSectionProps {}

const formName: keyof CreateEventFields = 'providers'
type ProviderGeneric = `${string}.${number}.${keyof CreateEventFields['providers'][number]}`

function ProvidersForm({ variant }: ProvidersFormProps) {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: formName,
    })

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Providers:
            </Heading>

            {fields.map((item, idx) => (
                <Fragment key={item.id}>
                    <HStack spacing={4}>
                        <Heading as="h4" fontSize="xl" mb={2}>
                            Provider {idx + 1}:
                        </Heading>
                        {fields.length > 1 ? (
                            <IconButton
                                w="8"
                                minW="8"
                                h="8"
                                minH="8"
                                aria-label={`Remove provider ${idx + 1}`}
                                icon={<MdDelete />}
                                onClick={() => remove(idx)}
                            />
                        ) : null}
                    </HStack>

                    <HookCreateEventInput<ProviderGeneric>
                        name={`${formName}.${idx}.name`}
                        label="Provider name:"
                        parentFormVariant={variant}
                        inputType="text"
                        validation={{
                            required: 'Provider name is required',
                        }}
                    />

                    <HookCreateEventInput<ProviderGeneric>
                        name={`${formName}.${idx}.bio`}
                        label="Provider bio:"
                        parentFormVariant={variant}
                        inputType="text"
                        validation={{
                            required: 'Provider bio is required',
                        }}
                    />
                </Fragment>
            ))}
            <Button
                variant="textButton"
                onClick={() => {
                    append(_.cloneDeep(newProvider))
                }}
                fontSize="md"
                my="-4"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another provider
            </Button>
        </CreateEventSection>
    )
}

export default ProvidersForm
