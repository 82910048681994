import { Button, Heading, HStack, Icon, IconButton } from '@chakra-ui/react'
import CreateEventFields from '@defs/CreateEventFields'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import HookCreateEventMultiInput from '@v1_atoms/HookCreateEventMultiInput/HookCreateEventMultiInput.component'
import { CreateEventSectionProps } from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { MdAdd, MdDelete } from 'react-icons/md'

export const newSubSection: CreateEventFields['additionalInfo'][number]['subSections'][number] = {
    subSectionTitle: '',
    text: [{ value: '' }],
}

export interface AdditionalInformationSubSectionFormProps extends CreateEventSectionProps {
    parentFormName: string
    sectionIndex: number
}
type SubSectionGeneric =
    `${string}.${number}.subSections.${number}.${keyof CreateEventFields['additionalInfo'][number]['subSections'][number]}`

function AdditionalInformationSubSectionForm({
    parentFormName,
    sectionIndex,
    variant,
}: AdditionalInformationSubSectionFormProps) {
    const formName: `${string}.${number}.subSections` = `${parentFormName}.${sectionIndex}.subSections`

    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: formName,
    })

    return (
        <>
            {fields.map((item, idx) => (
                <Fragment key={item.id}>
                    <HStack spacing={4}>
                        <Heading as="h5" fontSize="lg" mb={2}>
                            Subsection {idx + 1}:
                        </Heading>
                        {fields.length > 1 ? (
                            <IconButton
                                w="8"
                                minW="8"
                                h="8"
                                minH="8"
                                aria-label={`Remove subsection ${idx + 1} from section ${
                                    sectionIndex + 1
                                }`}
                                icon={<MdDelete />}
                                onClick={() => remove(idx)}
                            />
                        ) : null}
                    </HStack>
                    <HookCreateEventInput<SubSectionGeneric>
                        name={`${formName}.${idx}.subSectionTitle`}
                        label="Subsection title:"
                        parentFormVariant={variant}
                        inputType="text"
                        validation={{}}
                    />
                    <HookCreateEventMultiInput<SubSectionGeneric>
                        name={`${formName}.${idx}.text`}
                        label="Subsection text:"
                        parentFormVariant={variant}
                        inputType="textarea"
                        validation={{
                            required:
                                'Subsection text fields must have a value, you can remove ones you do not need (minimum one entry required)',
                        }}
                        addButtonText="Add another paragraph"
                        removeButtonAriaText={`remove from section ${sectionIndex + 1} subsection ${
                            idx + 1
                        } paragraph`}
                    />
                </Fragment>
            ))}
            <Button
                variant="textButton"
                onClick={() => {
                    append(_.cloneDeep(newSubSection))
                }}
                fontSize="md"
                my="-4"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another subsection
            </Button>
        </>
    )
}

export default AdditionalInformationSubSectionForm
