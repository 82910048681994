import { Heading } from '@chakra-ui/react'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'

export interface OrganiserDetailsFormProps extends CreateEventSectionProps {}

function OrganiserDetailsForm({ variant }: OrganiserDetailsFormProps) {
    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Organiser details
            </Heading>

            <HookCreateEventInput
                name="organiserId"
                label="ID:"
                parentFormVariant={variant}
                validation={{
                    required: 'Organiser ID is required',
                }}
                inputType="text"
            />

            <HookCreateEventInput
                name="organiserName"
                label="Name:"
                parentFormVariant={variant}
                validation={{
                    required: 'Organiser name is required',
                }}
                inputType="text"
            />
        </CreateEventSection>
    )
}

export default OrganiserDetailsForm
