import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    VStack,
} from '@chakra-ui/react'
import { GetEventImageMapResponse } from '@commonwealthventures/image-service-api-models'
import AddImageFields from '@defs/AddImagesFields'
import Result from '@defs/Result'
import { ImageField } from '@services/getS3SignedUrl.service'
import uploadImage from '@services/uploadImage.service'
import generateProviderImageKey from '@utils/generateProviderImageKey'
import ImageUploadBox from '@v1_atoms/ImageUploadBox/ImageUploadBox.component'
import EventQueryingPage, { EventWithImages } from '@v1_hocs/EventQueryingPage.component'
import ImageUploadFormSection from '@v1_molecules/ImageUploadFormSection/ImageUploadFormSection.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import _ from 'lodash'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

function uploadImageIfSet(
    eventId: string,
    field: ImageField,
    file: File | undefined,
    originalImageMap: GetEventImageMapResponse,
    authToken: string,
): Promise<Result<void>> {
    if (file) {
        return uploadImage({
            eventId,
            field,
            file,
            authToken,
            originalImageMap,
            onUploadProgress: () => undefined,
        })
    }

    return Promise.resolve({ success: true })
}

function AddEventImagesContents({ data }: { data: EventWithImages }) {
    const navigate = useNavigate()

    const methods = useForm<AddImageFields>({
        defaultValues: {
            imageApiToken: process.env.REACT_APP_IMAGE_API_POST_AUTH ?? '',
        },
    })

    const { id: eventId, providers, images } = data

    const [imagesUploading, setImagesUploading] = useState(false)

    const onSubmit = async (formData: AddImageFields) => {
        setImagesUploading(true)

        const uploadPrimary = uploadImageIfSet(
            eventId,
            'primary',
            formData.primary[0],
            images,
            formData.imageApiToken,
        )

        const uploadConfirmBig = uploadImageIfSet(
            eventId,
            'confirm/big',
            formData.confirmBig[0],
            images,
            formData.imageApiToken,
        )

        const uploadConfirmMedium = uploadImageIfSet(
            eventId,
            'confirm/medium',
            formData.confirmMedium[0],
            images,
            formData.imageApiToken,
        )

        const uploadConfirmSmall = uploadImageIfSet(
            eventId,
            'confirm/small',
            formData.confirmSmall[0],
            images,
            formData.imageApiToken,
        )

        const uploadOrganiser = uploadImageIfSet(
            eventId,
            'organiser',
            formData.organiser[0],
            images,
            formData.imageApiToken,
        )

        const uploadThankYou = uploadImageIfSet(
            eventId,
            'thank-you',
            formData.thankYou[0],
            images,
            formData.imageApiToken,
        )

        const uploadProviders =
            formData.providers?.map((item, idx) =>
                uploadImageIfSet(
                    eventId,
                    `providers/${generateProviderImageKey({ name: providers[idx].name, idx })}`,
                    item[0],
                    images,
                    formData.imageApiToken,
                ),
            ) ?? []

        const results = await Promise.all([
            uploadPrimary,
            uploadConfirmBig,
            uploadConfirmMedium,
            uploadConfirmSmall,
            uploadOrganiser,
            uploadThankYou,
            ...uploadProviders,
        ])

        setImagesUploading(false)

        if (results.every(({ success }) => success)) {
            navigate(`/events/${eventId}`)
            return
        }

        // eslint-disable-next-line no-alert
        alert('something went wrong uploading the images. Try again')
    }

    return (
        <FormProvider {...methods}>
            <VStack>
                <NavHeading menuColour="green" headingText="Turbafy" />
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <VStack spacing={8} maxW="800px" textColor="plejGreen.500" alignItems="start">
                        <Heading as="h2">Add images for your event</Heading>
                        <FormControl isInvalid={!!methods.formState.errors.imageApiToken}>
                            <FormLabel>Image API auth token:</FormLabel>
                            <Input
                                type="text"
                                {...methods.register('imageApiToken', {
                                    required: true,
                                })}
                            />
                            <FormErrorMessage>You must enter an image API token</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!methods.formState.errors.primary}>
                            <ImageUploadFormSection
                                sectionName="primary"
                                description="This image will be the image used as the thumbnail for you event on the events page and also be the background image people see when clicking into your event. Make sure its a good size to remain crisp on larger screen sizes."
                            >
                                <FormErrorMessage>Primary image is required</FormErrorMessage>
                                <ImageUploadBox name="primary" existingImageSrc={images.primary} />
                            </ImageUploadFormSection>
                        </FormControl>
                        <FormControl
                            isInvalid={
                                !!methods.formState.errors.confirmBig ||
                                !!methods.formState.errors.confirmMedium ||
                                !!methods.formState.errors.confirmSmall
                            }
                        >
                            <ImageUploadFormSection
                                sectionName="confirm and pay"
                                description="These images will be shown on the page where the customer selects which of the ticket options they wish to attend using"
                            >
                                <FormErrorMessage>
                                    All three confirm images are required
                                </FormErrorMessage>
                                <HStack w="full">
                                    <ImageUploadBox
                                        name="confirmBig"
                                        existingImageSrc={images.confirm?.big}
                                    />
                                    <ImageUploadBox
                                        name="confirmMedium"
                                        existingImageSrc={images.confirm?.medium}
                                    />
                                    <ImageUploadBox
                                        name="confirmSmall"
                                        existingImageSrc={images.confirm?.small}
                                    />
                                </HStack>
                            </ImageUploadFormSection>
                        </FormControl>
                        <FormControl isInvalid={!!methods.formState.errors.thankYou}>
                            <ImageUploadFormSection
                                sectionName="thank you"
                                description="This image will be the image shown when a customer has pledged to attend your event."
                            >
                                <FormErrorMessage>Thank you image is required</FormErrorMessage>
                                <ImageUploadBox
                                    name="thankYou"
                                    existingImageSrc={images['thank-you']}
                                />
                            </ImageUploadFormSection>
                        </FormControl>
                        <FormControl isInvalid={!!methods.formState.errors.organiser}>
                            <ImageUploadFormSection
                                sectionName="organiser"
                                description="This image will be the image shown as the avatar image for the organiser of this event."
                            >
                                <FormErrorMessage>Organiser image is required</FormErrorMessage>
                                <ImageUploadBox
                                    name="organiser"
                                    existingImageSrc={images.organiser}
                                />
                            </ImageUploadFormSection>
                        </FormControl>
                        <ImageUploadFormSection
                            sectionName="providers"
                            description="This image will be the image shown as the avatar image for the organiser of this event."
                        >
                            {providers.map(({ name }, idx) => (
                                <FormControl
                                    key={`provider-image-${name}`}
                                    isInvalid={
                                        !!_.get(methods.formState.errors, `providers.${idx}`)
                                    }
                                >
                                    <VStack w="full" mt={4} spacing={4} alignItems="start">
                                        <Heading
                                            as="h4"
                                            fontSize="xl"
                                            mb={2}
                                            textTransform="capitalize"
                                        >
                                            {name}
                                        </Heading>
                                        <FormErrorMessage>
                                            {name} image is required
                                        </FormErrorMessage>
                                        <ImageUploadBox
                                            name={`providers.${idx}`}
                                            existingImageSrc={
                                                images.providers
                                                    ? images.providers[
                                                          generateProviderImageKey({ name, idx })
                                                      ]
                                                    : undefined
                                            }
                                        />
                                    </VStack>
                                </FormControl>
                            ))}
                        </ImageUploadFormSection>
                    </VStack>
                    <Button type="submit" isLoading={imagesUploading} my={8}>
                        Add images
                    </Button>
                </form>
            </VStack>
        </FormProvider>
    )
}

export default function AddEventImages() {
    return (
        <EventQueryingPage>
            {(data: EventWithImages) => <AddEventImagesContents data={data} />}
        </EventQueryingPage>
    )
}
