import { Heading } from '@chakra-ui/react'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import HookCreateEventMultiInput from '@v1_atoms/HookCreateEventMultiInput/HookCreateEventMultiInput.component'
import HookCreateEventSelectInput from '@v1_atoms/HookCreateEventSelectInput/HookCreateEventSelectInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { getNames as getAllCountryNames, overwrite as overwriteCountry } from 'country-list'

overwriteCountry([
    {
        code: 'GB',
        name: 'United Kingdom',
    },
])

const countryNames = getAllCountryNames()

countryNames.sort()
countryNames.unshift(
    countryNames.splice(
        countryNames.findIndex((el) => el === 'United Kingdom'),
        1,
    )[0],
)

export interface VenueDetailsFormProps extends CreateEventSectionProps {}

function VenueDetailsForm({ variant }: VenueDetailsFormProps) {
    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Venue details:
            </Heading>

            <HookCreateEventMultiInput
                name="addressLines"
                label="Address lines:"
                parentFormVariant={variant}
                validation={{
                    required:
                        'Venue address lines must have a value, you can remove ones you do not need (minimum one entry required)',
                }}
                inputType="text"
                addButtonText="Add another address line"
                removeButtonAriaText="remove address line"
            />

            <HookCreateEventInput
                name="city"
                label="City:"
                parentFormVariant={variant}
                validation={{
                    required: 'Venue city is required',
                }}
                inputType="text"
            />

            <HookCreateEventInput
                name="postcode"
                label="Postcode:"
                parentFormVariant={variant}
                validation={{
                    required: 'Venue postcode is required',
                }}
                inputType="text"
            />

            <HookCreateEventSelectInput
                name="country"
                label="Country:"
                parentFormVariant={variant}
                validation={{
                    required: 'Venue country is required',
                }}
                options={countryNames}
                placeholder="Select a country"
            />
        </CreateEventSection>
    )
}

export default VenueDetailsForm
