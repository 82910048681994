import { Heading } from '@chakra-ui/react'
import inputDateToUtcDate from '@utils/inputDateToUtcDate'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { useFormContext } from 'react-hook-form'

export interface FundDetailsFormProps extends CreateEventSectionProps {}

function FundDetailsForm({ variant }: FundDetailsFormProps) {
    const { getValues } = useFormContext()

    const todayAsDate = new Date()
    todayAsDate.setHours(0)
    todayAsDate.setMinutes(0)
    todayAsDate.setSeconds(0)
    todayAsDate.setMilliseconds(0)

    const today = todayAsDate.getTime()

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Fund details
            </Heading>

            <HookCreateEventInput
                name="fundingDeadline"
                label="Funding deadline:"
                parentFormVariant={variant}
                triggerValidationInAnotherField={['traditionalPurchaseDeadline', 'startDate']}
                validation={{
                    required: 'Funding deadline is required',
                    pattern: {
                        value: /^\d{4}-\d{2}-\d{2}$/,
                        message: 'Funding deadline must be in DD/MM/YYYY format',
                    },
                    validate: (value) => {
                        const dateAsDate = inputDateToUtcDate(value)

                        const dateAsTime = dateAsDate.getTime()

                        if (dateAsTime < today) {
                            return 'Funding deadline cannot be in the past'
                        }

                        if (dateAsTime === today) {
                            return 'Funding deadline cannot be today'
                        }

                        if (dateAsTime <= today + 2 * 24 * 60 * 60 * 1000) {
                            return 'Funding deadline must be more than 2 days in the future'
                        }

                        const { traditionalPurchaseDeadline, startDate } = getValues()

                        if (traditionalPurchaseDeadline && traditionalPurchaseDeadline !== '') {
                            const traditionalPurchaseDeadlineAsDate = inputDateToUtcDate(
                                traditionalPurchaseDeadline,
                            )

                            if (dateAsTime > traditionalPurchaseDeadlineAsDate.getTime()) {
                                return 'Funding deadline must be on the same day or before traditional purchase deadline'
                            }
                        }

                        if (startDate && startDate !== '') {
                            const startDateAsDate = inputDateToUtcDate(startDate)

                            if (dateAsTime >= startDateAsDate.getTime()) {
                                return 'Funding deadline must be before the start date'
                            }
                        }

                        return true
                    },
                }}
                inputType="date"
            />

            <HookCreateEventInput
                name="traditionalPurchaseDeadline"
                label="Traditional purchase deadline:"
                parentFormVariant={variant}
                triggerValidationInAnotherField={['fundingDeadline', 'startDate']}
                validation={{
                    required: 'Traditional purchase deadline is required',
                    pattern: {
                        value: /^\d{4}-\d{2}-\d{2}$/,
                        message: 'Traditional purchase deadline must be in DD/MM/YYYY format',
                    },
                    validate: (value) => {
                        const dateAsDate = inputDateToUtcDate(value)

                        const dateAsTime = dateAsDate.getTime()

                        if (dateAsTime < today) {
                            return 'Traditional purchase deadline cannot be in the past'
                        }

                        if (dateAsTime === today) {
                            return 'Traditional purchase deadline cannot be today'
                        }

                        if (dateAsTime <= today + 2 * 24 * 60 * 60 * 1000) {
                            return 'Traditional purchase deadline must be more than 2 days in the future'
                        }

                        const { fundingDeadline, startDate } = getValues()

                        if (fundingDeadline && fundingDeadline !== '') {
                            const fundingDeadlineAsDate = inputDateToUtcDate(fundingDeadline)

                            if (dateAsTime < fundingDeadlineAsDate.getTime()) {
                                return 'Traditional purchase deadline must be on the same day or after funding deadline'
                            }
                        }

                        if (startDate && startDate !== '') {
                            const startDateAsDate = inputDateToUtcDate(startDate)

                            if (dateAsTime >= startDateAsDate.getTime()) {
                                return 'Traditional purchase deadline must be before the start date'
                            }
                        }

                        return true
                    },
                }}
                inputType="date"
            />

            <HookCreateEventInput
                name="fundingThreshold"
                label="Funding threshold:"
                parentFormVariant={variant}
                validation={{
                    valueAsNumber: true,
                    validate: (value) => {
                        if (Number.isNaN(value)) {
                            return 'Funding threshold must be a number'
                        }
                        if (value < 1) {
                            return 'Funding threshold must be greater than zero'
                        }
                        if (value % 1 !== 0) {
                            return 'Funding threshold must be a whole number'
                        }
                        return true
                    },
                }}
                inputType="number"
                inputMode="numeric"
            />

            <HookCreateEventInput
                name="maxPlaces"
                label="Maximum number of places available:"
                parentFormVariant={variant}
                validation={{
                    valueAsNumber: true,
                    validate: (value) => {
                        if (Number.isNaN(value)) {
                            return 'Maximum number of places available must be a number'
                        }
                        if (value < 1) {
                            return 'Maximum number of places available must be greater than zero'
                        }
                        if (value % 1 !== 0) {
                            return 'Maximum number of places available must be a whole number'
                        }
                        return true
                    },
                }}
                inputType="number"
                inputMode="numeric"
            />
        </CreateEventSection>
    )
}

export default FundDetailsForm
