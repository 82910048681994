import { Box, Button, Image, Input, Stack } from '@chakra-ui/react'
import AddImageFields from '@defs/AddImagesFields'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { MdUpload } from 'react-icons/md'

interface ImageUploadBoxProps {
    name: keyof AddImageFields | `providers.${string}`
    existingImageSrc?: string
}

function ImageUploadBox({ name, existingImageSrc }: ImageUploadBoxProps) {
    const inputRef = useRef<HTMLInputElement>(null)

    const [fileLink, setFileLink] = useState<string>()

    const { register, formState } = useFormContext()

    const {
        ref: registerRef,
        onChange: fieldOnChange,
        ...rest
    } = register(name, {
        required: !existingImageSrc,
    })

    const handleImageUploadChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
        if (evt.target.files) {
            const file = evt.target.files[0]
            const urlFileLink = URL.createObjectURL(file)

            setFileLink(urlFileLink)
            fieldOnChange(evt)
        }
    }

    const isInvalid = !!_.get(formState.errors, name)

    return (
        <Box
            bg="plejGrey.300"
            w="full"
            aspectRatio={3 / 2}
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            role="group"
            borderColor={isInvalid ? 'red.500' : 'plejGrey.300'}
            borderWidth="2pt"
            borderStyle="solid"
        >
            <Image position="absolute" objectFit="contain" src={fileLink ?? existingImageSrc} />
            <Stack
                display={fileLink || existingImageSrc ? 'none' : 'flex'}
                _groupHover={{
                    display: 'flex',
                }}
                justifyContent="center"
                w="full"
                direction="row"
            >
                <Button
                    rightIcon={<MdUpload />}
                    onClick={() => inputRef.current!.click()}
                    maxW="80%"
                >
                    Upload
                </Button>

                <Input
                    {...rest}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUploadChange}
                    display="none"
                    ref={(e) => {
                        registerRef(e)
                        ;(inputRef as any).current = e
                    }}
                />
            </Stack>
        </Box>
    )
}

export default ImageUploadBox
