import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react'
import CreateEventFields from '@defs/CreateEventFields'
import { CreateEventSectionProps } from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form'

export interface HookCreateEventSelectInputProps<T extends string = keyof CreateEventFields> {
    name: T
    label: string
    validation: RegisterOptions<FieldValues, T>
    options: string[]
    placeholder: string
    parentFormVariant: CreateEventSectionProps['variant']
}

function HookCreateEventSelectInput<T extends string = keyof CreateEventFields>({
    name,
    label,
    validation,
    parentFormVariant,
    options,
    placeholder,
}: HookCreateEventSelectInputProps<T>) {
    const { control, formState } = useFormContext()

    const inputVariant = parentFormVariant === 'white' ? 'createEventGreen' : 'createEventWhite'

    const isInvalid = !!_.get(formState.errors, name)

    return (
        <FormControl isInvalid={isInvalid}>
            <FormLabel>{label}</FormLabel>
            <Controller
                control={control}
                name={name as any}
                rules={validation}
                render={({ field }) => (
                    <Select
                        {...field}
                        placeholder={placeholder}
                        variant={inputVariant}
                        onChange={(e) => {
                            field.onChange(e)
                            field.onBlur()
                        }}
                    >
                        {options.map((option) => (
                            <option>{option}</option>
                        ))}
                    </Select>
                )}
            />
            {isInvalid ? (
                <FormErrorMessage>
                    {_.get(formState.errors, name)?.message?.toString() ?? ''}
                </FormErrorMessage>
            ) : null}
        </FormControl>
    )
}

export default HookCreateEventSelectInput
