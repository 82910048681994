import { Heading } from '@chakra-ui/react'
import inputDateToUtcDate from '@utils/inputDateToUtcDate'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import HookCreateEventMultiInput from '@v1_atoms/HookCreateEventMultiInput/HookCreateEventMultiInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import { useFormContext } from 'react-hook-form'

export type EventDetailsFormValues = {
    title: string
    summary: string
    detailedOverview: string
    startDate: string
    endDate: string
    whatsIncluded: string[]
    whatsNotIncluded: string[]
}

export interface EventDetailsFormProps extends CreateEventSectionProps {}

function EventDetailsForm({ variant }: EventDetailsFormProps) {
    const { getValues } = useFormContext()

    const todayAsDate = new Date()
    todayAsDate.setHours(0)
    todayAsDate.setMinutes(0)
    todayAsDate.setSeconds(0)
    todayAsDate.setMilliseconds(0)

    const today = todayAsDate.getTime()

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Event details
            </Heading>

            <HookCreateEventInput
                name="title"
                label="Title:"
                parentFormVariant={variant}
                validation={{
                    required: 'Event title is required',
                }}
                inputType="text"
            />

            <HookCreateEventInput
                name="summary"
                label="Summary:"
                parentFormVariant={variant}
                validation={{
                    required: 'Event summary is required',
                }}
                inputType="text"
            />

            <HookCreateEventInput
                name="detailedOverview"
                label="Detailed overview:"
                parentFormVariant={variant}
                validation={{
                    required: 'Event overview is required',
                }}
                inputType="textarea"
            />

            <HookCreateEventInput
                name="startDate"
                label="Start date:"
                parentFormVariant={variant}
                triggerValidationInAnotherField={[
                    'endDate',
                    'fundingDeadline',
                    'traditionalPurchaseDeadline',
                ]}
                validation={{
                    required: 'Event start date is required',
                    pattern: {
                        value: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/,
                        message: 'Event start date must be in DD/MM/YYYY HH:MM format',
                    },
                    validate: (value) => {
                        const dateAtMidnightDate = inputDateToUtcDate(value)

                        const dateAtMidnightTime = dateAtMidnightDate.getTime()

                        if (dateAtMidnightTime < today) {
                            return 'Start date cannot be in the past'
                        }

                        if (dateAtMidnightTime === today) {
                            return 'Start date cannot be today'
                        }

                        if (dateAtMidnightTime <= today + 2 * 24 * 60 * 60 * 1000) {
                            return 'Start date must be more than 2 days in the future'
                        }

                        const { endDate, fundingDeadline, traditionalPurchaseDeadline } =
                            getValues()

                        if (endDate && endDate !== '') {
                            const endDateAsDate = inputDateToUtcDate(endDate)

                            if (dateAtMidnightTime > endDateAsDate.getTime()) {
                                return 'Start date must be on the same day or before the end date'
                            }

                            if (endDate < value) {
                                return 'Starting time must be before ending time'
                            }

                            if (endDate === value) {
                                return 'Starting time must be before ending time. Cannot have zero length event'
                            }
                        }

                        if (fundingDeadline && fundingDeadline !== '') {
                            const fundingDeadlineAsDate = inputDateToUtcDate(fundingDeadline)

                            if (dateAtMidnightTime <= fundingDeadlineAsDate.getTime()) {
                                return 'Start date must be after funding deadline'
                            }
                        }

                        if (traditionalPurchaseDeadline && traditionalPurchaseDeadline !== '') {
                            const traditionalPurchaseDeadlineAsDate = inputDateToUtcDate(
                                traditionalPurchaseDeadline,
                            )

                            if (dateAtMidnightTime <= traditionalPurchaseDeadlineAsDate.getTime()) {
                                return 'Start date must be after traditional purchase deadline'
                            }
                        }

                        return true
                    },
                }}
                inputType="datetime-local"
            />

            <HookCreateEventInput
                name="endDate"
                label="End date:"
                parentFormVariant={variant}
                triggerValidationInAnotherField={['startDate']}
                validation={{
                    required: 'Event end date is required',
                    pattern: {
                        value: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/,
                        message: 'Event end date must be in DD/MM/YYYY HH:MM format',
                    },
                    validate: (value) => {
                        const dateAtMidnightDate = inputDateToUtcDate(value)

                        const dateAtMidnightTime = dateAtMidnightDate.getTime()

                        if (dateAtMidnightTime < today) {
                            return 'End date cannot be in the past'
                        }

                        if (dateAtMidnightTime === today) {
                            return 'End date cannot be today'
                        }

                        if (dateAtMidnightTime <= today + 2 * 24 * 60 * 60 * 1000) {
                            return 'End date must be more than 2 days in the future'
                        }

                        const { startDate } = getValues()

                        if (startDate && startDate !== '') {
                            const startDateAsDate = inputDateToUtcDate(startDate)

                            if (dateAtMidnightTime < startDateAsDate.getTime()) {
                                return 'End date must be on the same day or after the start date'
                            }

                            if (startDate > value) {
                                return 'Ending time must be after starting time'
                            }

                            if (startDate === value) {
                                return 'Ending time must be after starting time. Cannot have zero length event'
                            }
                        }

                        return true
                    },
                }}
                inputType="datetime-local"
            />

            <HookCreateEventMultiInput
                name="whatsIncluded"
                label="What's included:"
                parentFormVariant={variant}
                validation={{
                    required:
                        "What's included fields must have a value, you can remove ones you do not need (minimum one entry required)",
                }}
                inputType="text"
                addButtonText="Add another included item"
                removeButtonAriaText="remove included item"
            />

            <HookCreateEventMultiInput
                name="whatsNotIncluded"
                label="What's not included:"
                parentFormVariant={variant}
                validation={{
                    required:
                        "What's not included fields must have a value, you can remove ones you do not need (minimum one entry required)",
                }}
                inputType="text"
                addButtonText="Add another excluded item"
                removeButtonAriaText="remove excluded item"
            />
        </CreateEventSection>
    )
}

export default EventDetailsForm
