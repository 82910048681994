import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    fonts: {
        heading: `'DM Sans', sans-serif`,
        body: `'DM Sans', sans-serif`,
    },
    colors: {
        plejGreen: {
            100: '#d7e1d2',
            200: '#afc3a6',
            300: '#88a679',
            400: '#60884d',
            500: '#386A20',
            600: '#2c5419',
        },
        plejGrey: {
            200: '#F7F7F7',
            300: '#F6F6F6',
            400: '#E6E6E6',
            500: '#D6D6D6',
            600: '#C6C6C6',
            800: '#A6A6A6',
        },
        black: {
            500: '#111111',
            600: '#000000',
        },
    },
    components: {
        MenuTransition: {
            variants: {
                enter: {
                    bg: 'red',
                },
            },
        },
        Divider: {
            baseStyle: {
                borderColor: 'plejGrey.500',
            },
        },
        Button: {
            defaultProps: {
                colorScheme: 'plejGreen',
                size: 'lg',
            },
            baseStyle: {
                width: '100%',
                borderRadius: '64px',
                paddingY: '32px',
                fontWeight: '400',
            },
            variants: {
                textButton: {
                    textDecoration: 'underline',
                    width: 'auto',
                    border: '0',
                    padding: '0',
                    _hover: {
                        textDecoration: 'none',
                    },
                },
                round: {
                    minW: '64px',
                    maxW: '64px',
                    minH: '64px',
                    maxH: '64px',
                    backgroundColor: 'white',
                    borderColor: 'plejGrey.400',
                    borderStyle: 'solid',
                    color: 'black',
                    borderWidth: '1pt',
                    fontSize: '24px',
                    outline: 'none',
                    _hover: {
                        backgroundColor: 'plejGrey.500',
                    },
                    boxShadow: 'none',
                },
                white: {
                    backgroundColor: 'white',
                    color: 'plejGreen.500',
                    outline: 'none',
                    boxShadow: 'none',
                    _hover: {
                        backgroundColor: 'whiteAlpha.800',
                    },
                },
            },
        },
        Input: {
            variants: {
                createEventGreen: {
                    field: {
                        borderColor: 'plejGreen.500',
                        _hover: {
                            borderColor: 'plejGreen.200',
                        },
                        width: '100%',
                        borderWidth: '1pt',
                    },
                },
                createEventWhite: {
                    field: {
                        backgroundColor: 'plejGreen.500',
                        color: 'white',
                        borderColor: 'white',
                        _hover: {
                            borderColor: 'plejGreen.300',
                        },
                        width: '100%',
                        borderWidth: '1pt',
                    },
                },
            },
        },
        Select: {
            variants: {
                createEventGreen: {
                    field: {
                        borderColor: 'plejGreen.500',
                        _hover: {
                            borderColor: 'plejGreen.200',
                        },
                        width: '100%',
                        borderWidth: '1pt',
                    },
                },
                createEventWhite: {
                    field: {
                        backgroundColor: 'plejGreen.500',
                        color: 'white',
                        borderColor: 'white',
                        _hover: {
                            borderColor: 'plejGreen.300',
                        },
                        width: '100%',
                        borderWidth: '1pt',
                    },
                },
            },
        },
    },
})

export default theme
