import { Button, Heading, HStack, Icon, IconButton } from '@chakra-ui/react'
import CreateEventFields from '@defs/CreateEventFields'
import HookCreateEventInput from '@v1_atoms/HookCreateEventInput/HookCreateEventInput.component'
import CreateEventSection, {
    CreateEventSectionProps,
} from '@v1_organisms/CreateEventSection/CreateEventSection.component'
import _ from 'lodash'
import { Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { MdAdd, MdDelete } from 'react-icons/md'
import AdditionalInformationSubSectionForm, {
    newSubSection,
} from './AdditionalInformationSubSectionForm.component'

export const newSection: CreateEventFields['additionalInfo'][number] = {
    title: '',
    subSections: [_.cloneDeep(newSubSection)],
}
export interface AdditionalInformationFormProps extends CreateEventSectionProps {}

const formName: keyof CreateEventFields = 'additionalInfo'
type SectionGeneric = `${string}.${number}.${keyof CreateEventFields['additionalInfo'][number]}`

function AdditionalInformationForm({ variant }: AdditionalInformationFormProps) {
    const { control } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name: formName,
    })

    return (
        <CreateEventSection variant={variant}>
            <Heading as="h3" fontSize="2xl" mb={2}>
                Additional information:
            </Heading>
            {fields.map((item, idx) => (
                <Fragment key={item.id}>
                    <HStack spacing={4}>
                        <Heading as="h4" fontSize="xl" mb={2}>
                            Section {idx + 1}:
                        </Heading>
                        {fields.length > 1 ? (
                            <IconButton
                                w="8"
                                minW="8"
                                h="8"
                                minH="8"
                                aria-label={`Remove section ${idx + 1}`}
                                icon={<MdDelete />}
                                onClick={() => remove(idx)}
                            />
                        ) : null}
                    </HStack>

                    <HookCreateEventInput<SectionGeneric>
                        name={`${formName}.${idx}.title`}
                        label="Section title:"
                        parentFormVariant={variant}
                        inputType="text"
                        validation={{
                            required: 'Section title is required',
                        }}
                    />

                    <AdditionalInformationSubSectionForm
                        variant={variant}
                        parentFormName={formName}
                        sectionIndex={idx}
                    />
                </Fragment>
            ))}
            <Button
                variant="textButton"
                onClick={() => {
                    append(_.cloneDeep(newSection))
                }}
                fontSize="md"
                my="-4"
            >
                <Icon as={MdAdd} boxSize={4} /> Add another section
            </Button>
        </CreateEventSection>
    )
}

export default AdditionalInformationForm
